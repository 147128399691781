import React from 'reactn';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import axios from 'axios';

// Call it once in your app. At the root of your app is the best place
toast.configure();

const MySwal = withReactContent(Swal);

// Deve essere solo dati poi lo devo mappare per la formattazione etc
var products = [
    {
        id: 1,
        titolo: 'luca',
        testo: 'sardella',
        timestamp: 123910908,
        tipo: 10,
    },
    {
        id: 2,
        titolo: 'alfio',
        testo: 'morante',
        timestamp: 123910908,
        tipo: null,
    },
];

function formattaTimestamp(cell, row) {
    if (cell) {
        return (
            <span className='small'>
                {moment(cell, 'X').format('DD/MM/YYYY')}
                <br />
                alle {moment(cell, 'X').format('HH:mm:ss')}
            </span>
        );
    } else {
        return <span className='text-muted '>Non registrate</span>;
    }
}

function formattaTipo(cell, row) {
    // Fattura emessa
    if (cell == 10) {
        return (
            <span>
                <i className='fas fa-cloud-upload-alt text-success'></i>
            </span>
        );
    }
    // Modifica dati
    else if (cell == 20) {
        return (
            <span>
                <i className='fas fa-pencil-alt text-warning'></i>
            </span>
        );
    }
    // Fattura non emessa
    else if (cell == 30) {
        return (
            <span>
                <i className='fas fa-cloud-upload-alt text-danger'></i>
            </span>
        );
    } else if (cell == 40) {
        return (
            <span>
                <i className='fas fa-user-plus text-success'></i>
            </span>
        );
    }
    // Fattura eliminata
    else if (cell == 50) {
        return (
            <span>
                <i className='fas fa-trash-alt text-danger'></i>
            </span>
        );
    } else {
        return <span>X</span>;
    }
}

class LogsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '/logs',
            candidati: [],
            aggiornamentoInCorso: false,
            mostraRiepilogo: false,
            logs: products,
        };
    }

    async componentDidMount() {
        this.aggiornamentoLogs();

        return true;
    }

    async aggiornamentoLogs() {
        try {
            var l = await axios.get(this.global.apiBaseUrl + this.state.url);

            this.setState({
                logs: l.data.message.logs,
            });

            return true;
        } catch (errore) {
            alert(errore);
        }
    }

    render() {
        const colonne = [
            {
                dataField: 'titolo',
                text: 'Titolo',
                headerStyle: { width: '200px' },
                editable: false,
                style: { fontFamily: 'Quicksand , sans-serif' },
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 270,
                }),
            },
            {
                dataField: 'testo',
                text: 'Testo',
                editable: false,
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 270,
                    style: { maxWidth: '290px' },
                }),
            },
            {
                dataField: 'timestamp',
                text: 'Data e ora',
                formatter: formattaTimestamp,
                headerStyle: { width: '140px' },
                style: { fontFamily: 'Poppins' },
            },
            {
                dataField: 'tipo',
                text: 'Tipo',
                headerStyle: { width: '90px' },
                editable: false,
                editorClasses: 'editoro',
                formatter: formattaTipo,
                filter: selectFilter({
                    placeholder: 'Tutti',
                    options: {
                        10: 'Fattura emessa',
                        20: 'Modifica dati',
                        30: 'Fattura non emessa',
                        40: 'Utenza importata',
                        50: 'Fattura eliminata',
                    },
                }),
                style: { fontSize: '22px', textAlign: 'center' },
            },
        ];

        const tastoAggiornamento = this.state.aggiornamentoInCorso ? (
            <Button variant='success' style={{ opacity: '0.7' }} className='float-right'>
                <i className='fas fa-retweet mr-2'></i>
                Aggiornamento ....
            </Button>
        ) : (
            <Button variant='success' id='aggiornamentoFatture' onClick={() => this.aggiornamentoLogs()} className='btn-grower float-right'>
                <i className='fas fa-retweet mr-2'></i>
                Aggiorna
            </Button>
        );

        return (
            <>
                <div style={{ padding: '0' }}>
                    {tastoAggiornamento}

                    <br />

                    <BootstrapTable
                        classes={this.state.aggiornamentoInCorso ? 'mt-4 sfocato' : 'mt-4'}
                        striped
                        hover
                        condensed
                        keyField='id'
                        data={this.state.logs}
                        columns={colonne}
                        id='tabellaContatti'
                        rowStyle={{ fontSize: '14px', fontFamily: "'Poppins' , sans-serif" }}
                        filter={filterFactory()}
                        cellEdit={cellEditFactory({
                            mode: 'dbclick',
                        })}
                    />
                </div>
            </>
        );
    }
}

export default LogsTable;

import React from 'reactn';
import axios from 'axios';

import { BrowserRouter as Router } from 'react-router-dom';

import SideBar from './partials/SideBar.js';
import PageWrapper from './partials/PageWrapper.js';
import LogIn from './LogIn.js';

export default class MainView extends React.Component {
    async componentDidMount() {
        const response = await axios.get(this.global.apiBaseUrl + 'autenticazione/check');

        if (!response.status === 200) this.setGlobal({ utente: null });
        else this.setGlobal({ utente: response.data.message.utente });
    }

    render() {
        return (
            <>
                {this.global.utente ? (
                    <div className='d-flex'>
                        <Router>
                            <SideBar />
                            <PageWrapper />
                        </Router>
                    </div>
                ) : (
                    <LogIn />
                )}
            </>
        );
    }
}

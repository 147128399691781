import React from 'reactn';

import Container from 'react-bootstrap/Container';

import TopBar from './TopBar.js';

import Dashboard from '../Dashboard.js';
import Docendo from '../Docendo.js';
import Semplificaci from '../Semplificaci.js';
import Educare from '../Educare.js';
import Keyskills from '../Keyskills.js';
import Logforschool from '../Logforschool.js';
import Manuale from '../Manuale.js';
import Rubrica from '../Rubrica.js';
import Backlog from '../Backlog.js';
import Impostazioni from '../Impostazioni.js';
import NewContactPage from '../NewContactPage.js';

import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import { Switch } from 'react-router';

class PageWrapper extends React.Component {
    render() {
        return (
            <div style={{ width: '100%' }}>
                <TopBar />

                <Container>
                    <Switch>
                        <Route exact path='/' component={withRouter(Dashboard)} />
                        <Route exact path='/docendo' component={withRouter(Docendo)} />
                        <Route exact path='/semplificaci' component={withRouter(Semplificaci)} />
                        <Route exact path='/educare' component={withRouter(Educare)} />
                        <Route exact path='/keyskills' component={withRouter(Keyskills)} />
                        <Route exact path='/logforschool' component={withRouter(Logforschool)} />
                        <Route exact path='/rubrica' component={withRouter(Rubrica)} />
                        <Route exact path='/backlog' component={withRouter(Backlog)} />
                        <Route exact path='/impostazioni' component={withRouter(Impostazioni)} />
                        <Route exact path='/manuale' component={withRouter(Manuale)} />
                        <Route exact path='/nuovo-contatto' component={withRouter(NewContactPage)} />
                    </Switch>
                </Container>
            </div>
        );
    }
}

export default PageWrapper;

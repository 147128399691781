import React from 'reactn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import InvoicesTable from '../views/InvoicesTable/InvoicesTable.js';

class Educare extends React.Component {
    constructor(props) {
        super(props);

        const descrizioni = ['La sicurezza per il mondo scuola.'];

        this.state = {
            logs: null,
            piattaforma: {
                nome: 'eduCare',
                descrizione: descrizioni[Math.floor(Math.random() * descrizioni.length)],
                id: 3
            }
        };
    }

    loadingMessage = (
        <Row className='text-center text-muted' style={{ marginTop: '10%' }}>
            <Spinner className='spinnerCaricamento' style={{ width: '70px', height: '70px' }} animation='grow' variant='muted' />
            <h1 className='messaggioCaricamento'>Caricamento in corso ....</h1>
        </Row>
    );

    refresh = () => {
        this.setState({ logs: null });
        this.retrieveLogs();
    };

    retrieveLogs() {
        setTimeout(() => {
            this.setState({ logs: 'ready' });
        }, Math.random() * 10);
    }

    componentDidMount() {
        this.retrieveLogs();
    }

    render() {
        var hasContent = this.state.logs ? true : false;
        var content = !hasContent ? this.loadingMessage : <InvoicesTable piattaforma={this.state.piattaforma.nome} idPiattaforma={this.state.piattaforma.id} />;

        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='titoloPagina'>
                        {this.state.piattaforma.nome}
                        <small className='float-right sottoTitoloPagina'>{this.state.piattaforma.descrizione}</small>
                    </h2>
                    <p className='mt-4'>{content}</p>
                </Col>
            </Row>
        );
    }
}

export default Educare;

import React, { setGlobal } from 'reactn';

import MainView from './components/views/MainView.js';

export default class App extends React.Component {
    render() {
        return (
            <div className='App'>
                <MainView />
            </div>
        );
    }
}

import React from 'reactn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import LogsTable from './LogsTable/LogsTable.js';

class Backlog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logs: null
        };
    }

    loadingMessage = (
        <Row className='text-center text-muted' style={{ marginTop: '10%' }}>
            <Spinner className='spinnerCaricamento' style={{ width: '70px', height: '70px' }} animation='grow' variant='muted' />
            <h1 className='messaggioCaricamento'>Caricamento in corso ....</h1>
        </Row>
    );

    async retrieveLogs() {
        try {
            setTimeout(() => {
                this.setState({ logs: [1, 2, 3, 4] });
            }, 20);
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.retrieveLogs();
    }

    render() {
        var hasContent = this.state.logs ? true : false;
        var content = !hasContent ? this.loadingMessage : <LogsTable />;

        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='titoloPagina interna'>
                        Log eventi
                        <small className='float-right sottoTitoloPagina'>Meglio tenere traccia di tutto ....</small>
                    </h2>
                    <p className='mt-4'>{content}</p>
                </Col>
            </Row>
        );
    }
}

export default Backlog;

import React from "reactn";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

class NewContactPage extends React.Component {
  constructor(props) {
    super(props);

    const descrizioni = ["Certificazioni? Non studiare, compra!"];

    this.state = {
      logs: null
    };
  }

  loadingMessage = (
    <Row className="text-center text-muted" style={{ marginTop: "10%" }}>
      <Spinner
        className="spinnerCaricamento"
        style={{ width: "70px", height: "70px" }}
        animation="grow"
        variant="muted"
      />
      <h1 className="messaggioCaricamento">Caricamento in corso ....</h1>
    </Row>
  );

  async retrieveLogs() {
    try {
      setTimeout(() => {
        this.setState({ logs: [1, 2, 3, 4] });
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.retrieveLogs();
  }

  render() {
    var hasContent = this.state.logs ? true : false;
    var content = !hasContent ? this.loadingMessage : <h1>wewewewe</h1>;

    return (
      <Row>
        <Col className="p-4">
          <h2 className="titoloPagina interna">
            Nuovo contatto
            <small className="float-right sottoTitoloPagina">
              Perchè giustamente stavamo scarsi!
            </small>
          </h2>
          <p className="mt-4">
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Codice fiscale</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Categoria</Form.Label>
                <Form.Control as="select">
                  <option>Privato</option>
                  <option>Istituto</option>
                  <option>A.N.QU.A.P.</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label>Example multiple select</Form.Label>
                <Form.Control as="select" multiple>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Form.Group>

              <Form.Row>
                  <Form.Group  as={Col} controlId="exampleForm.ControlInput1">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
                  </Form.Group>

                  <Form.Group  as={Col} controlId="exampleForm.ControlInput1">
                    <Form.Label>Cognome</Form.Label>
                    <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
                  </Form.Group>
                  </Form.Row>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Denominazione</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Telefono</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>E-mail</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>C.A.P.</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Comune</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Provincia</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Regione</Form.Label>
                <Form.Control type="text" placeholder="AAAAAA11A11A111A" />
              </Form.Group>
            </Form>
          </p>
        </Col>
      </Row>
    );
  }
}

export default NewContactPage;

import React from 'reactn';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Button from 'react-bootstrap/Button';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';

import paginationFactory from 'react-bootstrap-table2-paginator';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import 'react-toggle/style.css';

import moment from 'moment';

//import ModaleRiepilogo from './ModaleRiepilogo.js';

import axios from 'axios';

// Call it once in your app. At the root of your app is the best place
toast.configure();

const MySwal = withReactContent(Swal);

const opzioniPaginazione = {
    firstPageText: 'Prima',
    lastPageText: 'Ultima',
    prePageText: 'Precedente',
    nextPageText: 'Prossima',
    sizePerPageList: [
        {
            text: '5',
            value: 5,
        },
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
        {
            text: '100',
            value: 100,
        },
        {
            text: '200',
            value: 200,
        },
    ],
    pageButtonRenderer: ({ page, active, disable, title, onPageChange }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };

        const activeStyle = {};

        if (active) {
            activeStyle.backgroundColor = 'navy';
            activeStyle.color = 'white';
            activeStyle.borderRadius = '2px';
            activeStyle.padding = '4px 10px';
            activeStyle.border = '1px solid darkslateblue';
            activeStyle.fontSize = '12px';
            activeStyle.fontWeight = 'bold';
            activeStyle.fontFamily = 'Poppins , sans-serif';
        } else {
            activeStyle.backgroundColor = 'white';
            activeStyle.color = 'darkslateblue';
            activeStyle.borderRadius = '2px';
            activeStyle.padding = '4px 10px';
            activeStyle.border = '1px solid darkslateblue';
            activeStyle.fontSize = '12px';
            activeStyle.fontWeight = 'normal';
            activeStyle.fontFamily = 'Poppins , sans-serif';
        }

        return (
            <li className='page-item'>
                <a href='#' onClick={handleClick} style={activeStyle}>
                    {page}
                </a>
            </li>
        );
    },
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className='btn-group listaDimensioniPagina float-right' role='group'>
            <label className='mb-0 text-muted'>
                <small>Dimensioni pagina:</small>
            </label>
            {options.map((option) => {
                const isSelect = currSizePerPage == option.page;

                return (
                    <button
                        key={option.text}
                        onClick={() => onSizePerPageChange(option.page)}
                        className={isSelect ? 'paginazioneAttiva' : 'paginazioneNonAttiva'}
                    >
                        {option.text}
                    </button>
                );
            })}
        </div>
    ),
    sizePerPageOptionRenderer: ({ text, page, onSizePerPageChange }) => (
        <li key={text} role='presentation' className='dropdown-item'>
            <a
                href='#'
                tabIndex='-1'
                role='menuitem'
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'darkslateblue', fontSize: '14px', fontFamily: 'Poppins , sans-serif' }}
            >
                {text}
            </a>
        </li>
    ),
};

// Deve essere solo dati poi lo devo mappare per la formattazione etc
var products = [
    {
        id_categoria: 1,
        id: 1,
        codice_fiscale: 'ALFRGUID01010101',
        nome: 'Alfio',
        cognome: 'Guidy',
        denominazione: null,
        id_reviso: null,
        telefono: null,
        email: 'guida.alfred@gmail.com',
        cap: 80050,
        comune: 'Napoli',
        provincia: 'NA',
        regione: 'Campania',
        data_importazione: 1497914711,
    },
    {
        id_categoria: 3,
        id: 2,
        codice_fiscale: '12729810',
        nome: null,
        cognome: null,
        denominazione: 'Istituto `Antonio Lombardi`',
        id_reviso: 4444,
        telefono: null,
        email: 'numendor43@gmail.com',
        cap: 90011,
        comune: 'Bergamo',
        provincia: 'RM',
        regione: 'Puglia',
        data_importazione: 1493284711,
    },
];

function formattaCategoriaUtente(cell, row) {
    if (row.id_categoria == 1) {
        return (
            <span title='PRIVATO ( id categoria Reviso: 1 )' style={{ color: '#474268' }}>
                <i className='fas fa-user mr-2'></i>
            </span>
        );
    } else if (row.id_categoria == 3) {
        return (
            <span title='ISTITUTO ( id categoria Reviso: 3 )' style={{ color: '#474268' }}>
                <i className='fas fa-graduation-cap mr-2'></i>
            </span>
        );
    } else if (row.id_categoria == 5) {
        return (
            <span title='ANQUAP ( id categoria Reviso: 5 )' style={{ color: 'darkslateblue' }}>
                <i className='fas fa-wheelchair mr-2'></i>
            </span>
        );
    } else {
        return (
            <span style={{ fontSize: '14px' }} className='text-danger'>
                [ ERRORE ]
            </span>
        );
    }
}

function formattaIdReviso(cell, row) {
    if (cell) {
        return <span className='idUtenteReviso'>{cell}</span>;
    } else {
        return <span className='text-muted '>Non registrato</span>;
    }
}

class ContactsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '/utenze',
            candidate: [],
            aggiornamentoInCorso: false,
            mostraRiepilogo: false,
            contatti: products,
            contattiCandidati: [],
        };
    }

    componentDidMount() {
        this.aggiornamentoContatti();
    }

    aggiornamentoContatti = async () => {
        try {
            this.setState({ aggiornamentoInCorso: true });

            var risultato = await axios.get(this.global.apiBaseUrl + this.state.url + '/lista');

            if (risultato.data) risultato = risultato.data.message.utenze;
            else throw new Error(JSON.stringify(risultato));

            toast.success(risultato.length + ' contatti trovati!', {
                position: 'top-center',
                hideProgressBar: true,
                autoClose: 2000,
                className: 'toasto',
                pauseOnHover: true,
            });

            this.setState({
                contatti: risultato,
                aggiornamentoInCorso: false,
                ultimoAggiornamento: moment().format('DD/MM/YYYY, HH:mm:ss'),
            });
        } catch (errore) {
            console.error(errore);
            alert(errore);

            this.setState({
                aggiornamentoInCorso: false,
            });
        }
    };

    fetchEmailFromDocendo = async (codice_fiscale) => {
        console.log(codice_fiscale, this.global, this.state);
        let mail = (await axios.get(this.global.apiBaseUrl + this.state.url + '/email-docendo/' + codice_fiscale)).data;
        console.log(mail);
        return mail;
    };

    render() {
        const tastoAggiornamento = this.state.aggiornamentoInCorso ? (
            <Button variant='success' style={{ opacity: '0.7' }} className='float-right'>
                <i className='fas fa-retweet mr-2'></i>
                Aggiornamento ....
            </Button>
        ) : (
            <Button variant='success' id='aggiornamentoFatture' onClick={this.aggiornamentoContatti} className='btn-grower float-right'>
                <i className='fas fa-retweet mr-2'></i>
                Aggiorna
            </Button>
        );

        const colonne = [
            {
                dataField: 'id_categoria',
                text: 'Categoria',
                formatter: formattaCategoriaUtente,
                filter: selectFilter({
                    placeholder: 'Tutte',
                    options: {
                        1: 'Privati',
                        3: 'Istituti',
                        5: 'Anquap',
                    },
                }),
                style: {
                    textAlign: 'center',
                    fontSize: '20px',
                },
                headerStyle: {
                    width: '100px',
                },
                clickToEdit: false,
                editable: false,
            },
            {
                dataField: 'codice_fiscale',
                text: 'C.F.',
                style: {
                    fontFamily: 'Source Code Pro , monospace',
                    color: 'darkslategrey',
                    letterSpacing: '0px',
                    fontWeight: 'bold',
                },
                headerStyle: { width: '170px' },
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                editable: true,
                validator: async (newValue, row, column, done) => {
                    try {
                        await axios.post(this.global.apiBaseUrl + 'utenze/modifica/' + row.codice_fiscale, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.codice_fiscale,
                        });

                        toast.success('Codice fiscale aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                },
                editorClasses: 'editoro',
                editorStyle: { padding: '1px', fontFamily: 'Source Code Pro , monospace' },
            },
            {
                dataField: 'email',
                text: 'E-mail',
                style: {
                    fontFamily: 'Source Code Pro , monospace',
                    color: 'darkslategrey',
                    letterSpacing: '0px',
                    fontWeight: 'bold',
                },
                headerStyle: { width: '170px' },
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                editable: true,
                /* editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    console.log(editorProps);
                    return (
                    <Form {...editorProps} onSubmit={()=>{editorProps.onUpdate()}}>
                        <Button variant='primary' size='md' onClick={ async () => {
                            let emailDocendo = await this.fetchEmailFromDocendo(row.codice_fiscale);
                            let newVal = "asd"//+emailDocendo;
                            value = newVal;
                            console.log(value);
                        } }>Recupera e-mail dall'utenza Docendo</Button>
                        <Form.Control type='email' name='email' value={value} size="sm"></Form.Control>
                    </Form>
                )}, */
                validator: async (newValue, row, column, done) => {
                    try {
                        await axios.post(this.global.apiBaseUrl + 'utenze/modifica/' + row.codice_fiscale, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.email,
                        });
                        toast.success('Codice fiscale aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });
                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                },
                editorClasses: 'editoro',
                editorStyle: { padding: '1px', fontFamily: 'Source Code Pro , monospace' },
            },
            {
                dataField: 'nome',
                text: 'Nome',
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                editable: true,
                validator: async (newValue, row, column, done) => {
                    try {
                        let response = await axios.post(this.global.apiBaseUrl + 'utenze/modifica/' + row.codice_fiscale, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.nome,
                        });

                        toast.success('Nome aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                },
                editorClasses: 'editoro',
                editorStyle: { padding: '1px' },
            },
            {
                dataField: 'cognome',
                text: 'Cognome',
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                editable: true,
                editorClasses: 'editoro',
                editorStyle: { padding: '1px' },
                validator: async (newValue, row, column, done) => {
                    try {
                        let response = await axios.post(this.global.apiBaseUrl + 'utenze/modifica/' + row.codice_fiscale, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.nome,
                        });

                        toast.success('Cognome aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                },
            },
            {
                dataField: 'denominazione',
                text: 'Denominazione',
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                editable: true,
                editorClasses: 'editoro',
                editorStyle: { padding: '1px' },
                validator: async (newValue, row, column, done) => {
                    try {
                        let response = await axios.post(this.global.apiBaseUrl + '/utenze/modifica/' + row.codice_fiscale, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.denominazione,
                        });

                        toast.success('Denominazione aggiornata!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                },
            },
            {
                dataField: 'comune',
                text: 'Comune',
                validator: async (newValue, row, column, done) => {
                    try {
                        let response = await axios.post(this.global.apiBaseUrl + 'utenze/modifica/' + row.codice_fiscale, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.comune,
                        });

                        toast.success('Comune aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                },
            },
            {
                dataField: 'id_reviso',
                text: 'ID Reviso',
                formatter: formattaIdReviso,
                headerStyle: { maxWidth: '110px', width: '110px' },
                editable: false,
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                editorClasses: 'editoro',
                editorStyle: { padding: '1px', fontFamily: 'Source Code Pro , monospace' },
            },
        ];
        /*

        const selectRow = {
            mode              : 'checkbox',
            clickToSelect     : false ,
            onSelect          : this.gestioneSelezione,
            onSelectAll       : this.gestioneSelezionaTutte ,
            style             : { backgroundColor : "rgba( 100 , 150 , 200 , 0.2 )" } ,
            headerColumnStyle : { color : "blue" }
        };
 */

        var ShowDocendoEmail = (props) => {
            const [docendoEmail, setDocendoEmail] = React.useState("");
            return <p><Button style={{fontSize: 'small'}} onClick={async () => {
                let dm = await this.fetchEmailFromDocendo(props.cf);
                console.log(dm);
                setDocendoEmail(dm || "[e-mail non trovata]");
            }}>Mostra e-mail utenza Docendo</Button><span style={{marginLeft: '1rem', color: '#066570'}}>{docendoEmail}</span></p>;
        };

        var espansione = {
            renderer: (row) => (
                <div className='rigaEspansa'>
                    <h6 style={{ fontSize: '14px', fontWeight: 'bold' }}>Dati utente:</h6>

                    <br />

                    <div className='row d-flex rigaDatiUtente'>
                        <div className='col-sm-2 colonnaDatiUtente'>
                            <label>Codice fiscale:</label>
                            <p>{row.codice_fiscale}</p>

                            <label>Nome completo:</label>
                            <p>
                                {row.nome} {row.cognome} {row.denominazione}
                            </p>

                            <label>Categoria:</label>
                            <p>
                                {row.id_categoria == 1 ? (
                                    <span>
                                        Privato <small>(id Reviso: 1)</small>
                                    </span>
                                ) : (
                                    ''
                                )}
                                {row.id_categoria == 3 ? (
                                    <span>
                                        Istituto <small>(id Reviso: 3)</small>
                                    </span>
                                ) : (
                                    ''
                                )}
                                {row.id_categoria == 5 ? (
                                    <span>
                                        A.N.QU.A.P. <small>(id Reviso: 5)</small>
                                    </span>
                                ) : (
                                    ''
                                )}
                            </p>

                            <label>Importato il:</label>
                            <p>{moment(row.data_importazione, 'X', true).isValid() ? moment(row.data_importazione, 'X').format('DD/MM/YYYY') : '-'}</p>
                        </div>

                        <div className='col-sm-2 colonnaDatiUtente'>
                            <label>Provenienza:</label>
                            <p>
                                {row.cap || 'Errore'} {row.comune || 'Errore'}, {row.provincia || 'Errore'} ( {row.regione || 'Errore'} )
                            </p>

                            <label>Telefono:</label>
                            <p>{row.telefono || 'Errore'}</p>

                            <label>E-Mail:</label>
                            <p>{row.email}</p>
                            <ShowDocendoEmail cf={row.codice_fiscale}></ShowDocendoEmail>

                            <label>Codice ufficio:</label>
                            <p>{row.codice_ufficio}</p>
                        </div>
                    </div>
                </div>
            ),
        };

        return (
            <>
                <div style={{ padding: '0' }}>
                    {tastoAggiornamento}

                    <br />

                    <BootstrapTable
                        classes={this.state.aggiornamentoInCorso ? 'mt-4 sfocato' : 'mt-4'}
                        striped
                        hover
                        condensed
                        keyField='codice_fiscale'
                        data={this.state.contatti}
                        columns={colonne}
                        expandRow={espansione} /*
                selectRow={ selectRow } */
                        rowStyle={{ fontSize: '14px', fontFamily: "'Poppins' , sans-serif" }}
                        filter={filterFactory()}
                        columnToggle
                        cellEdit={cellEditFactory({
                            mode: 'dbclick',
                        })}
                        pagination={paginationFactory(opzioniPaginazione)}
                        id='tabellaContatti'
                    />
                </div>
            </>
        );
    }
}

export default ContactsTable;

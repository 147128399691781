import React from 'reactn';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import InvoicesTable from '../views/InvoicesTable/InvoicesTable.js';

class Docendo extends React.Component {
    constructor(props) {
        super(props);

        const descrizioni = [
            'Non sono bugs, sono features!',
            'La formazione online a prova di D.S.G.A. .',
            'Luca non mi mentire.',
            'Se sono riuscito ad importare le fatture da Docendo merito un premio.',
            'Posso avere la fedina penale dello sviluppatore?',
            "Posso avere l'autografo di Sabato Simonetti?",
            'Nessuno ha detto che fare il D.S.G.A. fosse un lavoro.',
            "Antò, cos'è il C.I.G.?",
            'Non farlo bene, ma fallo presto!',
            "L'anno prossimo rifacciamo Docendo. Uguale.",
            'Ad Alfredo questa piattaforma è costata una fidanzata.',
            'Aridatece Robberto!',
            'Tonioooo!',
            'Antò non cacare il cazzo o glielo dico a Marinella!',
            'BAD GATEWAY',
            'Oops, non siamo riusciti a trovare quello che stavi cercando!',
            'Invece che aggiornare di continuo pensa a fatturare!',
            "Guinness World Records 2k18: 'The most bugged web app in existence'.",
            'Se vedete un A.N.Q.U.A.P. scappate!',
            "Antò par 'o cazz!",
            'Alfredo compra solo da Internet. Chissà perchè.',
            "Antò, salutm a pat't!",
            'Antò chiavati ai guppi',
            'Non ti ho iscritto io a quella newsletter!',
            'Non penso che quel caffè mi mancherà affatto.',
            'Ma che fine hanno fatto le Personas?',
            "Non c'è cosa piu` divina che di Antonio la cugina!",
            'Qualcuno dica a Luca che la ragazza si tiene al padre!',
            'Technical error 23: GDS has trouble understanding things.',
        ];

        this.state = {
            logs: null,
            piattaforma: {
                nome: 'Docendo',
                descrizione: descrizioni[Math.floor(Math.random() * descrizioni.length)],
                id: 1,
            },
        };
    }

    loadingMessage = (
        <Row className='text-center text-muted' style={{ marginTop: '10%' }}>
            <Spinner className='spinnerCaricamento' style={{ width: '70px', height: '70px' }} animation='grow' variant='muted' />
            <h1 className='messaggioCaricamento'>Caricamento in corso ....</h1>
        </Row>
    );

    refresh = () => {
        this.setState({ logs: null });
        this.retrieveLogs();
    };

    retrieveLogs() {
        setTimeout(() => {
            this.setState({ logs: 'ready' });
        }, Math.random() * 4);
    }

    componentDidMount() {
        this.retrieveLogs();
    }

    render() {
        var hasContent = this.state.logs ? true : false;
        var content = !hasContent ? this.loadingMessage : <InvoicesTable piattaforma={this.state.piattaforma.nome} idPiattaforma={this.state.piattaforma.id} />;

        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='titoloPagina'>{this.state.piattaforma.nome}</h2>
                    <div className='mt-4'>{content}</div>
                </Col>
            </Row>
        );
    }
}

export default Docendo;

import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class TopBar extends React.Component {
    render() {
        return (
            <Navbar style={{ height: '54px', display: 'none' }} bg='dark' variant='dark'>
                <Navbar.Brand href='#home'> </Navbar.Brand>
                <Nav className='mr-auto'>
                    <Nav.Link href='#home'> </Nav.Link>
                </Nav>
                <Form inline>
                    <Button variant='info'>v0.1</Button>
                </Form>
            </Navbar>
        );
    }
}

export default TopBar;

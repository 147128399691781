import React from 'reactn';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import LogItem from './partials/LogItem.js';

class Logforschool extends React.Component {
    state = {
        logs: null
    };

    loadingMessage = (
        <Row className='text-center text-muted' style={{ marginTop: '10%' }}>
            <Spinner style={{ fontSize: '4rem' }} animation='border' variant='muted' />
            <h1>Caricamento in corso ....</h1>
        </Row>
    );

    refresh = () => {
        this.setState({ logs: null });
        this.retrieveLogs();
    };

    retrieveLogs() {
        setTimeout(() => {
            this.setState({ logs: 'ready' });
        }, Math.random() * 10);
    }

    componentDidMount() {
        this.retrieveLogs();
    }

    render() {
        var hasContent = this.state.logs ? true : false;
        var content = !hasContent
            ? this.loadingMessage
            : this.state.logs.map(l => {
                  return <LogItem title={l.id} content={l.title} />;
              });

        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='text-primary font-weight-bold'>Logforschool</h2>
                    <Row className='mt-4'>
                        <Col>
                            <Button variant='primary' disabled={!this.state.logs} onClick={this.refresh}>
                                <i className='fas fa-sync'></i>
                            </Button>
                        </Col>
                    </Row>
                    <p className='mt-4'>{content}</p>
                </Col>
            </Row>
        );
    }
}

export default Logforschool;

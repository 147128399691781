import React from 'reactn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

class Manuale extends React.Component {
    constructor(props) {
        super(props);

        const immagini = [
            'https://cdn-static.dagospia.com/img/foto/11-2018/pene-1-1081721_tn.jpg',
            'https://www.gay.it/wp-content/uploads/2017/09/maschi-etero-1-480x320.jpg',
            'https://cdn-static.dagospia.com/img/patch/10-2016/museo-del-pene-islanda-842292.jpg',
            'https://www.notizie.it/wp-content/uploads/2017/11/peni1.png',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN9v1QCGbpLKbHFMCdkfT5Odfz9jaw6c91QNJbTq9jKmmn7Re4',
            'https://www.memonews.it/wp-content/uploads/2019/04/tipi-di-pene-00.jpg',
            'https://st3.depositphotos.com/11296934/15078/i/1600/depositphotos_150785616-stock-photo-penis-with-shaved-balls-erection.jpg',
            'https://www.commentimemorabili.it/wp-content/uploads/2018/01/peni-parco-03.jpg',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrWHOv0R28MjXIN6KiWhPw5_yBPfChMeO-JFO22YgUEgdaCUFs',
            'https://www.gay.it/wp-content/uploads/2013/03/pene_accorciaBASE-1-440x320.jpg',
        ];

        //immagini[ Math.floor( Math.random() * immagini.length ) ]

        this.state = {
            caricamentoInCorso: true,
            titolo: 'Manuale',
            immagine: immagini[Math.floor(Math.random() * immagini.length)],
        };
    }

    loadingMessage = (
        <Row className='text-center text-muted' style={{ marginTop: '10%' }}>
            <Spinner style={{ fontSize: '4rem' }} animation='border' variant='muted' />
            <h1>Caricamento in corso ....</h1>
        </Row>
    );

    componentDidMount() {
        this.setState({ caricamentoInCorso: false });
    }

    componentWillUnmount() {
        alert('Qui giace il corpo del desiderio.');
    }

    render() {
        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='titoloPagina d-block'>{this.state.titolo}</h2>

                    <h1 className='d-block' style={{ fontFamily: 'Quicksand, sans-serif' }}>
                        Qui non c'è più niente.
                    </h1>
                </Col>
            </Row>
        );
    }
}

export default Manuale;

import React from 'reactn';

class LogItem extends React.Component {
    render() {
        return (
            <div style={{ borderLeft: '4px solid teal', paddingLeft: '8px' }}>
                <h6>{this.props.title}</h6>
                <p className='small'>{this.props.content}</p>
            </div>
        );
    }
}

export default LogItem;

import React from 'reactn';
import Button from 'react-bootstrap/Button';

export default class TastoElimina extends React.Component {
    render() {
        return (
            <Button variant='danger' onClick={this.props.azione()} className='btn-grower mr-2'>
                <i className='fas fa-trash mr-2'></i>
                Elimina fattura/e
            </Button>
        );
    }
}

import React from "reactn";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { CharacterReveal } from "react-text-reveal";


export default class LogIn extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            accessoInCorso : false ,
            username : null ,
            password : null,
            animate : false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({animate: true})
        }, 7 );
        
    }

    aggiornaCampo = ( e ) => {

        this
        .setState( {
            [ e.target.name ] : e.target.value
        } );
    }

    LogIn = async () => {

        this
        .setState( {
            accessoInCorso : !this.state.accessoInCorso
        } );
        
        try {

            let response = await    axios
                                    .post( 
                                        this.global.apiBaseUrl + "autenticazione/login" , {
                                            username : this.state.username ,
                                            password : this.state.password
                                    } );

            if ( response.status === 200 ) {

                this
                .setGlobal( {
                    utente : {
                        username : this.state.username 
                    }
                } );
            }            
        }
        catch ( errore ) {

            console.error( errore );
            this
            .setState( {
                accessoInCorso : !this.state.accessoInCorso
            } );
        }
    }


    render() {

        return(
            <Container style={{ marginTop : "50px" }}>
                <Row>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-6">
                        
                        <div className={ this.state.accessoInCorso ? "card p-3 mx-auto login-card-accessoincorso" : "card p-3 login-card mx-auto"}>
                            
                        <CharacterReveal
                            animateOpacity={true}
                            canPlay={this.state.animate}
                            characterOffsetDelay={100} // ms
                            copy={["Plutone"]}
                            direction={"bottom"}
                            duration={2000} // ms
                            ease={"cubic-bezier(0,0.4,0.4,1)"}
                            offset={"40px"}
                            opacityDelay={125} // ms
                            perspective={true}
                            perspectiveFOV={2005} // px
                            perspectiveX={26}
                            multilineMasking={true}
                            multilineOffsetDelay={100} // ms
                            className="loginAnimatedText"
                        />
                            
                            <h5 className="text-center login-card-title">
                                { this.state.accessoInCorso ? "Accesso in corso..." : "Effettua l'accesso" }
                            </h5>
                            <br />
                            <Form onSubmit={ () => this.LogIn() }>
                                <div className="form-group">
                                    <Form.Control disabled={ this.state.accessoInCorso } onChange={ ( e ) => this.aggiornaCampo( e ) } name="username" type="text" className="form-control" placeholder="Username"/>
                                </div>
                                <div className="form-group">
                                    <Form.Control disabled={ this.state.accessoInCorso } onChange={ ( e ) => this.aggiornaCampo( e ) } name="password" id="password-login" type="password" className="form-control" placeholder="Password"/>
                                </div>
                                <div className="form-group">
                                    {
                                        this.state.accessoInCorso ? 
                                            <Button disabled onClick={ () => this.LogIn() } className="btn btn-primary btn-block" type="button">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </Button>   
                                        :
                                            <Button onClick={ () => this.LogIn() } className="btn btn-primary btn-block" type="button">
                                                LogIn
                                            </Button>
                                    }
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </Row>
            </Container>
        )
    }
}
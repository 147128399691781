import React from 'reactn';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import { Link } from 'react-router-dom';

import '../../../node_modules/react-vis/dist/style.css';

import { RadialChart } from 'react-vis';

import axios from 'axios';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fattureRegistrate: {
                docendo: {
                    totali: 1,
                    emesse: 2,
                    daEmettere: 3,
                },
                semplificaci: {
                    totali: 1,
                    emesse: 2,
                    daEmettere: 3,
                },
                educare: {
                    totali: 1,
                    emesse: 2,
                    daEmettere: 3,
                },
                keyskills: {
                    totali: 1,
                    emesse: 2,
                    daEmettere: 3,
                },
            },
            counterDocendo: 0,
            counterSemplificaci: 0,
            counterEducare: 0,
            counterKeyskills: 0,
            todoDocendo: 0,
            todoSemplificaci: 0,
            todoEducare: 0,
            todoKeyskills: 0,

            importiDaFatturare: {
                docendo: 0,
                semplificaci: 0,
                educare: 0,
                keyskills: 0,
            },
        };

        var timer = null;
    }

    componentDidMount() {
        this.aggiornamentoContatori();

        const _this = this;

        this.timer = setInterval(() => {
            _this.aggiornamentoContatori();
        }, 2000);
    }

    async aggiornamentoContatori() {
        let contatori = await axios.get(this.global.apiBaseUrl + 'fatture/contatori');

        contatori = contatori.data.message.contatori;

        let importi = await axios.get(this.global.apiBaseUrl + 'fatture/importi-da-fatturare');

        importi = importi.data.message.importi;

        this.setState({
            counterDocendo: contatori.docendo.fatture_totali,
            counterSemplificaci: contatori.semplificaci.fatture_totali,
            counterEducare: contatori.educare.fatture_totali,
            counterKeyskills: contatori.keyskills.fatture_totali,

            todoDocendo: contatori.docendo.fatture_totali - contatori.docendo.fatture_emesse,
            todoSemplificaci: contatori.semplificaci.fatture_totali - contatori.semplificaci.fatture_emesse,
            todoEducare: contatori.educare.fatture_totali - contatori.educare.fatture_emesse,
            todoKeyskills: contatori.keyskills.fatture_totali - contatori.keyskills.fatture_emesse,

            fattureRegistrate: {
                docendo: {
                    totali: 1,
                    emesse: 1,
                    daEmettere: 1,
                },
                semplificaci: {
                    totali: 1,
                    emesse: 1,
                    daEmettere: 1,
                },
                educare: {
                    totali: 1,
                    emesse: 1,
                    daEmettere: 1,
                },
                keyskills: {
                    totali: 1,
                    emesse: 1,
                    daEmettere: 1,
                },
            },

            importiDaFatturare: {
                docendo: importi.docendo || 0,
                semplificaci: importi.semplificaci || 0,
                educare: importi.educare || 0,
                keyskills: importi.keyskills || 0,
            },
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const importiDaFatturare = this.state.importiDaFatturare;

        const dataa = [
            {
                angle: this.state.counterDocendo,
                label: 'Docendo',
                color: '#48cfdb',
            },
            {
                angle: this.state.counterEducare,
                label: 'eduCare',
                color: '#db7648',
            },
            {
                angle: this.state.counterKeyskills,
                label: 'KeySkills',
                color: '#6a48db ',
            },
            {
                angle: this.state.counterSemplificaci,
                label: 'SemplificaCi',
                color: '#db4848',
            },
        ];

        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='titoloPagina'>Dashboard</h2>

                    <p>
                        <br />
                    </p>

                    <Row style={{ display: 'flex' }}>
                        <Col xs={12} md={8} style={{ display: 'flex' }}>
                            <Card id='docendoCard' className='dashboardCard'>
                                <Card.Body>
                                    <Link to='/docendo'>
                                        <Card.Title className='dashboardCardTitle font-weight-bold'>Docendo</Card.Title>
                                        <Card.Text className='logCounter text-right'>
                                            {this.state.counterDocendo} fatture
                                            <p style={{ color: '#c59696', fontSize: '18px' }}>{this.state.todoDocendo} da emettere</p>
                                        </Card.Text>
                                    </Link>
                                </Card.Body>
                            </Card>

                            <Card id='semplificaciCard' className='dashboardCard'>
                                <Card.Body>
                                    <Link to='/semplificaci'>
                                        <Card.Title className='dashboardCardTitle font-weight-bold'>SemplificaCi</Card.Title>
                                        <Card.Text className='logCounter text-right'>
                                            {this.state.counterSemplificaci} fatture
                                            <p style={{ color: '#c59696', fontSize: '18px' }}>{this.state.todoSemplificaci} da emettere</p>
                                        </Card.Text>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6} md={4} style={{ marginLeft: '110px', textAlign: 'center' }}>
                            <RadialChart data={dataa} animation colorType='literal' innerRadius={20} width={120} height={120} />

                            <label className='etichettaChiara'>
                                <small>Distribuzione</small>
                            </label>
                        </Col>
                    </Row>

                    <br />

                    <Container>
                        <Row className='mt-4'>
                            <Col className='mt-4'>
                                <h6 style={{ fontWeight: 'bold', color: 'darkslateblue', opacity: '0.7', fontFamily: 'Barlow Condensed' }}>
                                    Importi da fatturare:
                                </h6>

                                <Table className='ultimeAttivita' hover>
                                    <thead>
                                        <tr>
                                            <th>Piattaforma</th>
                                            <th>Importo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className='badge badge-docendo'>docendo</span>
                                            </td>
                                            <td className='text-success'>{importiDaFatturare.docendo} €</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className='badge badge-semplificaci'>SemplificaCi</span>
                                            </td>
                                            <td className='text-success'>{importiDaFatturare.semplificaci} €</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        );
    }
}

export default Dashboard;

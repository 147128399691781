import React from 'reactn';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Table from 'react-bootstrap/Table';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter, selectFilter, dateFilter, Comparator, customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

import Modal from 'react-bootstrap/Modal';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import moment from 'moment';
import axios from 'axios';
import TastoElimina from './TastoElimina.js';
//import DateBetweenFilter from '../partials/DateBetweenFilter.js';

// Call it once in your app. At the root of your app is the best place
toast.configure();

const MySwal = withReactContent(Swal);

function formattaNomeCliente(cell, row) {
    return (
        <span style={{ cursor: 'pointer' }}>
            <span style={{ lineHeight: '90%' }} className='d-block'>
                {row.nome_cliente}
            </span>
            <span className='d-block' style={{ fontSize: '12px', fontFamily: "'Source Code Pro', monospace", opacity: '0.8' }}>
                {row.codice_fiscale_utente}
            </span>
        </span>
    );
}

function formattaHeader(column, colIndex) {
    return <span className='invoicesTableHeader'>{column.text}</span>;
}

function imponibileFormatter(cell, row) {
    return (
        <>
            <span style={{ fontSize: '1.4em', fontFamily: "'Barlow Condensed'", letterSpacing: '0' }} className='soldi'>
                {cell} €
            </span>
        </>
    );
}

function userCatFormatter(cell, row) {
    if (row.id_categoria_utente === 1) {
        return (
            <span title='PRIVATO ( id categoria Reviso: 1 )' style={{ color: '#474268' }}>
                <i className='fas fa-user mr-2'></i>
            </span>
        );
    } else if (row.id_categoria_utente === 3) {
        return (
            <span title='ISTITUTO ( id categoria Reviso: 3 )' style={{ color: '#474268' }}>
                <i className='fas fa-graduation-cap mr-2'></i>
            </span>
        );
    } else if (row.id_categoria_utente === 5) {
        return (
            <span title='ANQUAP ( id categoria Reviso: 5 )' style={{ color: 'darkslateblue' }}>
                <i className='fas fa-wheelchair mr-2'></i>
            </span>
        );
    } else {
        return (
            <span style={{ fontSize: '14px' }} className='text-danger'>
                [ ERRORE ]
            </span>
        );
    }
}

function formattaId(cell, row) {
    return (
        <span className='idFattura'>
            <small>#</small>
            {cell}
        </span>
    );
}

function formattaMetodoPagamento(cell, row) {
    const id_metodo_pagamento = cell;

    if (id_metodo_pagamento === 1) return <span>Bonifico</span>;
    else if (id_metodo_pagamento === 2) return <span>PayPal</span>;
    else if (id_metodo_pagamento === 20) return <span>Altro</span>;
    else return <span>[Errore]</span>;
}

function formattaData(cell, row) {
    return <span className='dataora'>{moment(cell, 'X', true).isValid() ? moment(cell, 'X').format('DD/MM/YYYY') : '-'}</span>;
}
function formattaDataCSV(cell, row) {
    return moment(cell, 'X', true).isValid() ? moment(cell, 'X').format('DD/MM/YYYY HH:mm:ss') : '-';
}

var _fattureCandidate = [];

class InvoicesTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            piattaforma: this.props.piattaforma.toLowerCase(),
            idPiattaforma: this.props.idPiattaforma,
            invioInCorso: false,
            aggiornamentoInCorso: false,
            aggiornamentoKeapInCorso: false,
            ultimoAggiornamento: '01/01/2001',
            mostraRiepilogo: false,
            includiEmesse: false,
            filtroDataInizio: '',
            filtroDataFine: '',
            fatture: [],
            fattureInviate: [],
            fattureNonInviate: [],
            fattureCandidate: [],
            rowsLength: 0,
            esportaSoloRisultatiFiltrati: true,
        };
    }

    componentDidMount() {
        // Fake click che si occupa di aggiornare
        // la tabella, che altrimenti non chiama
        // il formatter alla modifica
        document.getElementById('aggiornamentoFatture').click();
    }

    // Pop-up dettagli fattura
    dettagliFattura = (idFattura) => {
        const fattura = this.state.fatture.filter((f) => f.id === idFattura)[0];
        let quantita = fattura.dettagli ? JSON.parse(fattura.dettagli)[0].quantity : 1;

        MySwal.fire({
            title: <p>Dettagli fattura</p>,
            width: '70%',
            confirmButtonText: 'Chiudi',
            confirmButtonClass: 'modalButton btn-grower',
            html:
                '<div>' +
                "<ul id='listaDettagliFattura'>" +
                '<li>' +
                '<p>' +
                '<strong>Numero fattura:</strong>' +
                "<span><small style='opacity:0.4;'>#</small>" +
                fattura.id +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Codice fiscale cliente:</strong>' +
                "<span className='cf'>" +
                fattura.codice_fiscale_utente +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Nome completo cliente:</strong>' +
                '<span>' +
                fattura.nome_cliente +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Categoria cliente:</strong>' +
                '<span>' +
                (fattura.id_categoria_utente == 1
                    ? 'Privato'
                    : fattura.id_categoria_utente == 3
                    ? 'Istituto'
                    : fattura.id_categoria_utente == 5
                    ? 'A.N.QU.A.P.'
                    : 'Errore') +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Prodotto acquistato:</strong>' +
                '<span> "' +
                fattura.nome_prodotto +
                '" </span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Quantità:</strong>' +
                '<span>' +
                quantita +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Data di acquisto:</strong>' +
                '<span>' +
                (moment(fattura.data_acquisto, 'X', true).isValid()
                    ? moment(fattura.data_acquisto, 'X').format('DD/MM/YYYY')
                    : "<span className='errore'>Errore</span>") +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong> Imponibile:</strong>' +
                '<span>' +
                fattura.imponibile +
                ' € </span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>I.V.A.:</strong>' +
                '<span>' +
                (fattura.iva ? fattura.iva + ' %' : "<span style='opacity:0.7;'>Non applicata</span>") +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>C.I.G.:</strong>' +
                "<span className='cf'>" +
                (fattura.cig ? fattura.cig.toUpperCase() : "<span style='opacity:0.7;'>Non utilizzato</span>") +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong> Fattura importata il: </strong>' +
                '<span>' +
                (moment(fattura.data_importazione, 'X', true).isValid()
                    ? moment(fattura.data_importazione, 'X').format('DD/MM/YYYY')
                    : "<span className='errore'>Errore</span>") +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong> Piattaforma di origine: </strong>' +
                '<span>' +
                (fattura.id_piattaforma == 1
                    ? 'Docendo'
                    : fattura.id_piattaforma == 2
                    ? 'SemplificaCI'
                    : fattura.id_piattaforma == 3
                    ? 'eduCare'
                    : "<span className='errore'>Errore</span>") +
                '</span>' +
                '</p>' +
                '</li>' +
                '<li>' +
                '<p>' +
                '<strong>Fattura inviata a Reviso:</strong>' +
                '<span>' +
                (moment(fattura.data_emissione, 'X', true).isValid() ? moment(fattura.data_emissione, 'X').format('DD/MM/YYYY') : 'No') +
                '</span>' +
                '</p>' +
                '</li>' +
                `<li>
                    <p>
                        <strong>
                            Costi di spedizione:
                        </strong>
                        <span>
                            ${fattura.imponibile_spedizione || 0} € + ${fattura.iva_spedizione || 0} % I.V.A.
                        </span>
                    </p>
                </li>` +
                (fattura.keap_id ? `<li><p><strong>ID ordine Keap:</strong><span>${fattura.keap_id}</span></p></li>` : '') +
                (fattura.keap_error ? `<li><p><strong>Errore sincronizzazione Keap:</strong><span>${fattura.keap_error}</span></p></li>` : '') +
                '</ul>' +
                '</div>',
        });
    };

    gestioneSelezione = (row, isSelect) => {
        // Se nell'array delle fatture selezionate manca l'id di
        // quella appena selezionata ce lo aggiungo
        if (_fattureCandidate.indexOf(row.id) == -1) _fattureCandidate.push(row.id);
        else {
            // Altrimenti
            // Rimuovo l'elemento dell'array contenente
            // l'id della fattura appena selezionata.
            for (let i = 0; i < _fattureCandidate.length; i++) {
                if (_fattureCandidate[i] === row.id) _fattureCandidate.splice(i, 1);
            }
        }
    };

    gestioneSelezionaTutte = (isSelect, rows) => {
        if (isSelect) {
            return rows.map((r) => {
                _fattureCandidate.push(r.id);
                return r.id;
            });
        }
    };

    gestioneRiepilogo = () => {
        this.setState({
            mostraRiepilogo: !this.state.mostraRiepilogo,
        });
    };

    // Chiusura della modale di riepilogo invio fatture
    // e svuotamento degli array fattureInviate e fattureNonInviate
    chiudiRiepilogo = () => {
        this.setState({
            mostraRiepilogo: false,
            fattureInviate: [],
            fattureNonInviate: [],
        });
    };

    aggiornaFatture = async () => {
        this.setState({
            aggiornamentoInCorso: true,
        });

        try {
            // Aggiungo parametri all'url in base ai parametri della richiesta
            let url = 'fatture/lista/';
            if (this.state.idPiattaforma) url += this.state.idPiattaforma;
            url += '?includiEmesse=' + (this.state.includiEmesse ? 'true' : 'false');
            if (this.state.filtroDataInizio) {
                url += '&dataInizio=' + Date.parse(this.state.filtroDataInizio) / 1000;
            }
            if (this.state.filtroDataFine) {
                url += '&dataFine=' + Date.parse(this.state.filtroDataFine) / 1000; // +86400 se si vuole includere la dataFine
            }

            var risultato = await axios.get(this.global.apiBaseUrl + url);

            if (risultato.data.code == 200) risultato = risultato.data.message.fatture;
            else throw JSON.stringify(risultato.data.message + ': ' + risultato.data.errors);

            this.setState({
                fatture: risultato,
                aggiornamentoInCorso: false,
                fattureCandidate: [],
                ultimoAggiornamento: moment().format('DD/MM/YYYY, HH:mm:ss'),
            });
        } catch (errore) {
            toast.error(errore, {
                position: 'top-center',
                hideProgressBar: true,
                autoClose: 2000,
                pauseOnHover: true,
            });

            this.setState({
                fatture: [],
                aggiornamentoInCorso: false,
            });
        }
    };

    setFiltroDataInizio = (e) => {
        this.setState({ filtroDataInizio: e.target.value });
    };
    setFiltroDataFine = (e) => {
        let data = Date.parse(e.target.value);
        this.setState({ filtroDataFine: e.target.value });
    };

    toggleInvoiceLock = async (invoice) => {
        try {
            await axios.post(this.global.apiBaseUrl + '/fatture/modifica/' + invoice.id, {
                attributo: 'blocco',
                vecchioValore: invoice.blocco,
                valore: invoice.blocco === 0 ? 1 : 0,
            });

            const verb = invoice.blocco === 0 ? 'bloccata' : 'sbloccata';

            const invoicesSnapshot = this.state.fatture;

            for (let invoiceData of invoicesSnapshot) {
                if (invoiceData.id === invoice.id) invoiceData = invoice;
            }

            this.setState({
                ...this.state,
                fatture: invoicesSnapshot,
            });

            this.aggiornaFatture();

            toast.success('Fattura #' + invoice.id + ' ' + verb + '!', {
                position: 'top-center',
                hideProgressBar: true,
                autoClose: 2000,
                className: 'toasto',
                pauseOnHover: true,
            });
        } catch (error) {
            console.error(error);

            toast.error('Error toggling invoice #' + invoice.id + ' lock.', {
                position: 'top-center',
                hideProgressBar: true,
                autoClose: 2000,
                className: 'toasto',
                pauseOnHover: true,
            });
        }
    };

    toggleEmesse = () => {
        this.setState({ includiEmesse: !this.state.includiEmesse });
    };

    toggleEsportaSoloFiltrati = () => {
        this.setState({ esportaSoloRisultatiFiltrati: !this.state.esportaSoloRisultatiFiltrati });
    };

    copia = (testo) => {
        var textField = document.createElement('textarea');
        textField.innerText = testo;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    // Metodo per l'eliminazione fatture
    eliminazioneFatture = async () => {
        let confermaEliminazione = prompt(
            'Stai per eliminare una o più fatture. L\'operazione è irreversibile. Se ne sei consapevole, scrivi: "ne ho contezza" e clicca su OK. Grazie.'
        );
        if (confermaEliminazione != 'ne ho contezza') return false;

        let f_c = _fattureCandidate;

        for await (const idFattura of f_c) {
            try {
                let res = await axios.post(this.global.apiBaseUrl + '/fatture/elimina/' + idFattura);
                res = res.data;

                if (res.code != 200) throw new Error(res.message);

                toast.success('Fattura #' + idFattura + ' eliminata.', {
                    position: 'top-center',
                    hideProgressBar: true,
                    autoClose: 2000,
                    className: 'toasto',
                    pauseOnHover: true,
                });
            } catch (errore) {
                console.error(errore);

                toast.error('Fattura #' + idFattura + 'non eliminata.', {
                    position: 'top-center',
                    hideProgressBar: true,
                    autoClose: 2000,
                    className: 'toasto',
                    pauseOnHover: true,
                });
            }
        }

        _fattureCandidate = [];
        this.setState({ fattureCandidate: [] });

        await this.aggiornaFatture();
    };

    /*
        - FLOW:

            1. Recupera array di id delle fatture da inviare
            1.b. ( TODO ) Sottrai gli id delle fatture con l'invio bloccato
            2. Crea array vuoto che userai per salvare gli id delle fatture inviate con successo
            3. Invia le fatture una a una ( o meglio con Promise.all() ), e salva gli id di quelle inviate nell'array di prima
            4. Alert ( 4/8 fatture inviate! )
            5.a Flagga le fattura inviate con successo come inviate nel database ( forse è automatico dal metodo dell'invio )
            5.b Sottrai alla tabella le fatture il cui id era nell'array di quelle inviate con successo ( forse è meglio solo riaggiornare tanto poi staranno flaggate a mestiere )

    */
    invioFatture = async () => {
        //var f_c = this.state.fattureCandidate;
        // 1
        var f_c = _fattureCandidate;

        // Controllo che ci sia almeno 1 fattura selezionata
        if (f_c.length <= 0) return alert("Nessuna fattura selezionata per l'emissione.");

        // Setto il flag di invio in corso
        this.setState({ invioInCorso: true });

        // 2. Inizializzo gli array che conterranno gli id delle fatture inviate o non
        var fattureInviate = [];
        var fattureNonInviate = [];

        // 3. Per ogni fattura tra quelle da inviare
        for await (const fc of f_c) {
            try {
                // Tentiamo l'emissione della fattura
                const res = await axios.post(this.global.apiBaseUrl + '/reviso/invio-fattura/' + fc);

                // Mi salvo il campo 'data' che è quello che mi ha restituito il server
                const risposta = res.data;

                // Se il server mi ha ritornato una risposta positiva
                if (risposta.code == 200 || risposta.code == 201) {
                    // 4. Aggiungo l'oggetto corrispondente a questa fattura
                    //    all'array delle fatture inviate.
                    fattureInviate.push(
                        this.state.fatture.find((f) => {
                            return f.id == fc;
                        })
                    );

                    toast.success('Fattura #' + fc + ' inviata.', {
                        autoClose: 2000,
                        hideProgressBar: true,
                        position: 'top-center',
                        pauseOnHover: true,
                        className: 'toasto',
                    });
                }
                // Altrimenti lo valuto come errore
                else {
                    // 4. Aggiungo l'oggetto corrispondente a questa fattura
                    //    all'array delle fatture non inviate.
                    let fatt = this.state.fatture.find((f) => {
                        return f.id == fc;
                    });

                    fatt['errore'] = JSON.stringify(risposta.errors);

                    // Aggiungo la fattura alla lista di quelle che non sono risucito a inviare
                    fattureNonInviate.push(fatt);

                    toast.error('Fattura #' + fc + ' non inviata.', {
                        position: 'top-center',
                        hideProgressBar: true,
                        autoClose: 2000,
                        className: 'toasto',
                        pauseOnHover: true,
                    });
                }
            } catch (errore) {
                console.error(errore);

                let fatt = this.state.fatture.find((f) => {
                    return f.id == fc;
                });
                fatt['errore'] = JSON.stringify(errore);
                fattureNonInviate.push(fatt);

                toast.error('Fattura #' + fc + ' non inviata.', {
                    autoClose: 2000,
                    pauseOnHover: true,
                    hideProgressBar: true,
                    position: 'top-center',
                    className: 'toasto',
                });
            }
        }

        // Segno che ho finito di inviare le fatture selezionate
        this.setState({
            fattureInviate: fattureInviate,
            fattureNonInviate: fattureNonInviate,
            fattureCandidate: [],
            invioInCorso: false,
            mostraRiepilogo: true,
        });

        // Svuoto l'array delle fatture selezionate
        _fattureCandidate = [];
    };

    // aggiorna fatture su Keap
    aggiornaKeap = async () => {
        // disabilita pulsante
        this.setState({ aggiornamentoKeapInCorso: true });

        // tenta l'invio a keap
        const res = (
            await axios.post(this.global.apiBaseUrl + '/fatture/aggiorna-keap', {
                fattureSelezionate: _fattureCandidate || [],
            })
        ).data;
        console.log(res);

        // feedback
        if (res.code < 400) {
            toast.success(res.message, {
                autoClose: 2000,
                hideProgressBar: true,
                position: 'top-center',
                pauseOnHover: true,
                className: 'toasto',
            });
        } else {
            toast.error(res.message, {
                autoClose: 2000,
                hideProgressBar: true,
                position: 'top-center',
                pauseOnHover: true,
                className: 'toasto',
            });
        }

        // aggiorna stato e mostra riepilogo
        let fattureInviate = res.sent;
        let fattureNonInviate = res.errors.map((e) => ({ ...e.fattura, errore: JSON.stringify(e.errore) }));
        this.setState({
            fattureInviate,
            fattureNonInviate,
            aggiornamentoKeapInCorso: false,
            mostraRiepilogo: true,
            fattureCandidate: [],
        });
        _fattureCandidate = [];
    };

    beforeSaveCell = async (oldValue, newValue, row, column, done) => {
        setTimeout(() => {
            var ciao = window.confirm('we?');

            if (ciao) done(true);
            else done(false);
        }, 0);

        return { async: true };
    };

    render() {
        const _this = this;

        const colonne = [
            {
                dataField: 'keap_id',
                text: 'Keap',
                headerStyle: { width: '40px' },
                formatter: (cell, row) => (
                    <span>
                        {row.keap_error ? (
                            <i title={row.keap_error} className='fas fa-times' style={{ color: 'red' }}></i>
                        ) : cell ? (
                            <i title={`ID ${row.id}, K ${cell}`} className='fas fa-check' style={{ color: 'green' }}></i>
                        ) : (
                            ''
                        )}
                    </span>
                ),
            },
            {
                dataField: 'nome_cliente',
                text: 'Cliente',
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                    onFilter: (value) => {
                        let f1,
                            f2 = [];
                        f1 = this.state.fatture.filter((f) => f.codice_fiscale_utente.toUpperCase().includes(value.toUpperCase()));
                        f2 = this.state.fatture.filter((f) => f.nome_cliente.toUpperCase().includes(value.toUpperCase()));
                        const ff = [...new Set([...f1, ...f2])];
                        return ff;
                    },
                }),
                formatter: formattaNomeCliente,
                editorClasses: 'editoro',
                editable: false,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.copia(row.codice_fiscale_utente);
                        toast.info('Codice fiscale copiato!', {
                            position: 'top-center',
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            className: 'toasto',
                        });
                    },
                },
            },
            {
                dataField: 'codice_fiscale_utente',
                text: 'Codice Fiscale',
                hidden: true,
                editable: false,
            },
            {
                dataField: 'id_categoria_utente',
                text: 'Categoria',
                formatter: userCatFormatter,
                filter: selectFilter({
                    placeholder: 'Tutte',
                    options: {
                        1: 'Privati',
                        3: 'Istituti',
                        5: 'Anquap',
                    },
                }),
                style: {
                    textAlign: 'center',
                    fontSize: '20px',
                },
                headerStyle: {
                    width: '6em',
                },
                clickToEdit: false,
                editorClasses: 'editoro',
                editable: false,
            },
            {
                dataField: 'nome_prodotto',
                text: 'Prodotto',
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                headerStyle: { maxWidth: '18em' },
                editorClasses: 'editoro',
                style: { color: 'darkslategrey' },
                formatter: (cell, row) => (
                    <p style={{ lineHeight: '0.9rem' }}>
                        <span style={{ fontWeight: 'bold' }}>{row.dettagli ? ' (x ' + row.dettagli.match(/(?<=quantity":)[\d]/g)[0] + ') ' : ''}</span>
                        {row.nome_prodotto}
                        <span>{row.imponibile_spedizione ? ' (+spedizione)' : ''}</span>
                    </p>
                ),
            },
            {
                dataField: 'imponibile',
                text: 'Imponibile',
                formatter: imponibileFormatter,
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                style: { textAlign: 'center' },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                },
                sortCaret: (order, column) => {
                    if (!order)
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );
                    else if (order === 'asc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/
                                    <font color='red'>
                                        <i className='fas fa-sort-up'></i>
                                    </font>
                                </small>
                            </span>
                        );
                    else if (order === 'desc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;
                                    <font color='red'>
                                        <i className='fas fa-sort-down'></i>
                                    </font>
                                    /<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );

                    return null;
                },
                validator: async (newValue, row, column, done) => {
                    try {
                        await axios.post(this.global.apiBaseUrl + 'fatture/modifica/' + row.id, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.imponibile,
                        });

                        // TODO: refactor into generico "fieldUpdatedToastMessage"
                        toast.success(column.dataField + ' aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        console.error(error);

                        toast.error(`${error.response.data.message}: ${JSON.stringify(error.response.data.errors)}.`, {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: false,
                            message: `${error.response.data.message}: ${JSON.stringify(error.response.data.errors)}.`,
                        });
                    }
                },
                editorClasses: 'editoro',
            },
            {
                dataField: 'iva',
                text: 'IVA',
                editorClasses: 'editoro',
                editorStyle: {
                    padding: '1px',
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') return b - a;
                    else return a - b; // desc
                },
                sortCaret: (order, column) => {
                    if (!order)
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );
                    else if (order === 'asc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/
                                    <font color='red'>
                                        <i className='fas fa-sort-up'></i>
                                    </font>
                                </small>
                            </span>
                        );
                    else if (order === 'desc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;
                                    <font color='red'>
                                        <i className='fas fa-sort-down'></i>
                                    </font>
                                    /<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );

                    return null;
                },
                headerStyle: {
                    width: '90px',
                },
                formatter: (cell, row) => {
                    if (!cell) return <span></span>;
                    else return <span className='text-monospace'>{cell} %</span>;
                },
                validator: async (newValue, row, column, done) => {
                    try {
                        let response = await axios.post(this.global.apiBaseUrl + 'fatture/modifica/' + row.id, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.iva,
                        });

                        toast.success(column.text + ' aggiornata!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: true,
                            message: 'successone',
                        });
                    } catch (error) {
                        toast.error(column.text + ' non aggiornata!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        return done({
                            valid: false,
                            message: 'Errore 500',
                        });
                    }
                    //return { async: true };
                },
            },
            {
                dataField: 'cig',
                text: 'CIG',
                editorClasses: 'editoro',
                editable: true,
                filter: textFilter({
                    placeholder: 'Cerca ....',
                    delay: 400,
                }),
                style: { fontFamily: "'Source Code Pro' , monospace", textTransform: 'uppercase' },
                headerStyle: { width: '114px' },
                validator: async (newValue, row, column, done) => {
                    try {
                        await axios.post(this.global.apiBaseUrl + 'fatture/modifica/' + row.id, {
                            attributo: column.dataField,
                            valore: newValue,
                            vecchioValore: row.cig,
                        });

                        toast.success(column.text + ' aggiornato!', {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        document.getElementById('aggiornamentoFatture').click();

                        return done({ valid: true });
                    } catch (error) {
                        alert(error.message);

                        console.error(error);

                        return done({ valid: false });
                    }
                },
            },
            {
                dataField: 'data_acquisto',
                text: 'Data di acquisto',
                sort: true,
                formatter: formattaData,
                csvFormatter: formattaDataCSV,
                /* filter: textFilter({
                    delay: 400,
                    onFilter: (value) => {  // formato: "YYYY-MM-DD,YYYY-MM-DD"
                        let [from, to] = value.split(',').map(d => Date.parse(d)/1000);
                        console.log(value,from,to);
                        return this.state.fatture.filter((f) => {
                            return (!from || f.data_acquisto >= from) && (!to || f.data_acquisto <= to);
                        });
                    },
                }),
                filterRenderer: (onFilter, column) => <DateBetweenFilter onFilter={ onFilter } column={ column } />,
                filterValue: cell => new Date(cell*1000), */
                sortCaret: (order, column) => {
                    if (!order)
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );
                    else if (order === 'asc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/
                                    <font color='red'>
                                        <i className='fas fa-sort-up'></i>
                                    </font>
                                </small>
                            </span>
                        );
                    else if (order === 'desc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;
                                    <font color='red'>
                                        <i className='fas fa-sort-down'></i>
                                    </font>
                                    /<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );

                    return null;
                },
                clickToEdit: false,
                editable: true,
                blurToSave: true,
                editorStyle: {
                    padding: '1px',
                },
                editorClasses: 'editoro',
                headerStyle: { width: '152px' },
                validator: async (newValue, row, column, done) => {
                    try {
                        let nuovaData = moment(newValue, 'DD/MM/YYYY').format('X');

                        await axios.post(this.global.apiBaseUrl + 'fatture/modifica/' + row.id, {
                            attributo: column.dataField,
                            valore: nuovaData,
                            vecchioValore: row.data_acquisto,
                        });

                        toast.success("Data dell'acquisto aggiornata!", {
                            position: 'top-center',
                            hideProgressBar: true,
                            autoClose: 2000,
                            className: 'toasto',
                            pauseOnHover: true,
                        });

                        // Fake click che si occupa di aggiornare
                        // la tabella, che altrimenti non chiama
                        // il formatter alla modifica
                        document.getElementById('aggiornamentoFatture').click();

                        return done({
                            valid: true,
                            message: 'OK',
                        });
                    } catch (errore) {
                        alert(errore);
                        console.error(errore);

                        return done({
                            valid: false,
                            message: 'KO',
                        });
                    }
                },
            },
            {
                dataField: 'data_importazione',
                text: 'Importata il',
                sort: true,
                formatter: formattaData,
                csvFormatter: formattaDataCSV,
                sortCaret: (order, column) => {
                    if (!order)
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );
                    else if (order === 'asc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/
                                    <font color='red'>
                                        <i className='fas fa-sort-up'></i>
                                    </font>
                                </small>
                            </span>
                        );
                    else if (order === 'desc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;
                                    <font color='red'>
                                        <i className='fas fa-sort-down'></i>
                                    </font>
                                    /<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );

                    return null;
                },
                clickToEdit: false,
                editable: false,
                editorClasses: 'editoro',
                headerStyle: {
                    width: '144px',
                },
            },
            {
                dataField: 'data_emissione',
                text: 'Emessa il',
                sort: true,
                formatter: formattaData,
                csvFormatter: formattaDataCSV,
                sortCaret: (order, column) => {
                    if (!order)
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );
                    else if (order === 'asc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;<i className='fas fa-sort-down'></i>/
                                    <font color='red'>
                                        <i className='fas fa-sort-up'></i>
                                    </font>
                                </small>
                            </span>
                        );
                    else if (order === 'desc')
                        return (
                            <span className='sortCaretSpan'>
                                <small>
                                    &nbsp;&nbsp;
                                    <font color='red'>
                                        <i className='fas fa-sort-down'></i>
                                    </font>
                                    /<i className='fas fa-sort-up'></i>
                                </small>
                            </span>
                        );

                    return null;
                },
                clickToEdit: false,
                editable: false,
                editorClasses: 'editoro',
                headerStyle: {
                    width: '144px',
                },
            },
            {
                dataField: 'azioni',
                text: 'Azioni',
                isDummyField: true,
                headerStyle: { width: '80px' },
                editable: false,
                formatExtraData: _this.dettagliFattura,
                style: { textAlign: 'center' },
                formatter: (cell, row, df) => {
                    const lockButtonClasses = row.blocco === 1 ? 'ml-2 fas fa-unlock text-danger iconButton' : 'ml-2 fas fa-lock text-muted iconButton';

                    return (
                        <span>
                            <i onClick={() => _this.dettagliFattura(row.id)} className='fas fa-info-circle cTeal iconButton'></i>

                            <i onClick={() => _this.toggleInvoiceLock(row)} className={lockButtonClasses}></i>
                        </span>
                    );
                },
            },
            {
                dataField: 'blocco',
                text: 'Blocco',
                hidden: true,
            },
        ];

        const { ExportCSVButton } = CSVExport;

        const modale = (
            <Modal show={this.state.mostraRiepilogo} onHide={this.chiudiRiepilogo} size='lg' dialogClassName='modal-riepilogoInvioFatture'>
                <Modal.Header closeButton>
                    <Modal.Title>Riepilogo invio fattura/e:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='font-weight-bold m-0'>Fatture inviate:</label>
                    <Table style={{ border: '4px solid #454545' }} className='fattureInviate' striped bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th>
                                    <i>Id fattura</i>
                                </th>
                                <th>Codice fiscale cliente</th>
                                <th>Nome cliente</th>
                                <th>Nome prodotto</th>
                                <th>Importo + I.V.A.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.fattureInviate.length > 0 ? (
                                this.state.fattureInviate.map((fi) => {
                                    return (
                                        <tr key={fi.id}>
                                            <td>
                                                <small>#</small>
                                                {fi.id}
                                            </td>
                                            <td>{fi.codice_fiscale_utente}</td>
                                            <td>{fi.nome_cliente}</td>
                                            <td>"{fi.nome_prodotto}"</td>
                                            <td className='soldi'>
                                                {fi.imponibile} €<small style={{ color: 'grey !important' }}>( {fi.iva} % IVA )</small>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <td colspan='5' className='text-center' style={{ color: 'darkslategrey', fontSize: '18px', opacity: '0.4' }}>
                                    Nessuna.
                                </td>
                            )}
                        </tbody>
                    </Table>

                    <br />

                    <label className='font-weight-bold m-0'>Fatture non inviate:</label>
                    <Table style={{ border: '4px solid #454545' }} className='fattureNonInviate' striped bordered hover size='sm'>
                        <thead style={{ fontSize: '10px' }}>
                            <tr>
                                <th>
                                    <i>id</i>
                                </th>
                                <th>codice fiscale</th>
                                <th>nome completo</th>
                                <th>prodotto</th>
                                <th>importo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.fattureNonInviate.length > 0 ? (
                                this.state.fattureNonInviate.map((fni) => {
                                    return (
                                        <>
                                            <tr key={fni.id}>
                                                <td>
                                                    <small>#</small>
                                                    {fni.id}
                                                </td>
                                                <td>{fni.codice_fiscale_utente}</td>
                                                <td>{fni.nome_cliente}</td>
                                                <td>"{fni.nome_prodotto}"</td>
                                                <td className='soldi'>{fni.imponibile} €</td>
                                            </tr>
                                            <tr>
                                                <td colspan='5' style={{ borderBottom: '2px solid darkgrey' }}>
                                                    <strong className='mr-2'>Errore:</strong>
                                                    {fni.errore}
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })
                            ) : (
                                <td colspan='5' className='text-center' style={{ color: 'darkslategrey', fontSize: '18px', opacity: '0.4' }}>
                                    Nessuna.
                                </td>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-grower btn-main' onClick={this.chiudiRiepilogo}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const tastoAggiornamento = this.state.aggiornamentoInCorso ? (
            <Button variant='success' style={{ opacity: '0.7' }} className='float-right'>
                <i className='fas fa-retweet mr-2'></i>
                Aggiornamento ....
            </Button>
        ) : (
            <Button variant='success' id='aggiornamentoFatture' onClick={this.aggiornaFatture} className='btn-grower float-right'>
                <i className='fas fa-retweet mr-2'></i>
                Aggiorna
            </Button>
        );

        /* Il tasto di invio viene disattivato se l'invio è in corso */
        const tastoInvio = this.state.invioInCorso ? (
            <Button className='mr-2' variant='primary' style={{ backgroundColor: '#75aacb' }} onClick={this.invioFatture}>
                <Spinner animation='grow' size='sm' style={{ position: 'relative', top: '-4px', marginRight: '10px' }} />
                Invio fattura/e in corso ....
            </Button>
        ) : (
            <Button id='invioFatture' className='mr-2 btn-grower' variant='primary' onClick={this.invioFatture}>
                <i className='fas fa-paper-plane mr-2'></i>
                Invia fattura/e
            </Button>
        );

        const tastoAggiornaKeap = this.state.aggiornamentoKeapInCorso ? (
            <Button className='mr-2' variant='primary' style={{ backgroundColor: '#75aacb' }}>
                <Spinner animation='grow' size='sm' style={{ position: 'relative', top: '-4px', marginRight: '10px' }} />
                Sincronizzazione fattura/e a Keap in corso ....
            </Button>
        ) : (
            <Button id='aggiornaKeap' className='mr-2 btn-grower' variant='primary' onClick={this.aggiornaKeap}>
                <i className='fas fa-sync mr-2'></i>
                Sincronizzazione fattura/e con Keap
            </Button>
        );
        const mostraInfoSincroKeap = () => {
            MySwal.fire({
                type: 'info',
                title: <p>Sincronizzazione fatture con Keap</p>,
                width: '70%',
                confirmButtonText: 'Chiudi',
                confirmButtonClass: 'modalButton btn-grower',
                html: `<p class="text-sm">Questa funzione recupera tutte le fatture selezionate già inviate a Reviso ma non ancora sincronizzate con Keap. Per ciascuna crea un oggetto Order su Keap.<br/>
                <p>Per evitare timeout della richiesta, vengono sincronizzate <b>al massimo 15 fatture alla volta</b>.</p>
                <ul>
                <li>Le fatture con lo stesso CIG e tipo prodotto vengono accorpate (con gli stessi criteri di Reviso).</li>
                <li>Se non esiste il <span style="font-weight: 900;">contatto</span> Keap dell'acquirente, tenta di crearlo in base alle informazioni del DB Docendo. Se l'utente non esiste su Docendo, falliscono sia la creazione dell'utente che l'invio della fattura.</li>
                <li>Se non esistono su Keap tutti i <span style="font-weight: 900;">prodotti</span> inclusi in una fattura, l'ordine Keap non viene creato.</li>
                </ul></p>
                <h3 className="swal2-title">Nota per l'ambiente di sviluppo/test</h3>`,
            });
        };

        const mostraInfoCSVFattureKeap = () => {
            MySwal.fire({
                type: 'info',
                title: <p>Import fatture su Keap da CSV</p>,
                width: '70%',
                confirmButtonText: 'Chiudi',
                confirmButtonClass: 'modalButton btn-grower',
                html: `<div style="text-align: left;">
                <p>Questa funzione permette di caricare un file CSV con i dati di <span style="font-weight: 900;">fatture vecchie</span> (non presenti su Docendo e Plutone).<br />
                Le fatture non verranno caricate su Docendo, Plutone o Reviso, ma solo su Keap.<br />
                I contatti degli acquirenti e i prodotti devono essere <span style="font-weight: 900;">già stati caricati</span> su Keap. Se un prodotto o un contatto relativi a una fattura non esistono su Keap, quella fattura <span style="font-weight: 900;">non verrà caricata.</span></p>
                <h4 style="font-size: 1.5rem;">Header:</h4>
                <p style="padding:1rem;background-color:#ddd;text-align:left;font-family:monospace;">
                Numero fattura, ID Contatto, Data acquisto, Metodo pagamento, Importo, ID prodotto1, Prezzo prodotto1, ... ,ID prodottoN, Prezzo prodottoN
                </p>
                <p><ul>
                <li>Gli header si possono mettere in qualsiasi ordine</li>
                <li>Se il contatto è un istituto, usare l'ID del contatto associato</li>
                <li>Data acquisto in formato yyyy-mm-dd</li>
                <li>Metodo pagamento: CREDIT_CARD, CASH, CHECK oppure TOKEN</li>
                <li>Importo e prezzi singoli: usare il punto decimale</li>
                </ul></p>
                </div>`,
            });
        };
        const caricaCSVFattureKeap = async () => {
            // Caricamento file CSV
            let { value: csvFattureKeap } = await MySwal.fire({
                title: <p>Import fatture su Keap da CSV</p>,
                input: 'file',
                inputAttributes: {
                    accept: 'text/plain, text/csv',
                },
                width: '70%',
                cancelButtonText: 'Annulla',
                cancelButtonClass: 'modalButton btn-grower',
                confirmButtonText: 'Carica CSV',
                confirmButtonClass: 'modalButton btn-grower',
                html: `<h4 style="font-size: 1.5rem;">Header:</h4><p style="margin:0.5rem;padding:1rem;background-color:#ddd;text-align:left;font-family:monospace;">Numero fattura, ID Contatto, Data acquisto, Metodo pagamento, Importo, ID prodotto1, Prezzo prodotto1, ... ,ID prodottoN, Prezzo prodottoN</p>`,
            });
            console.log('csvFattureKeap', csvFattureKeap, typeof csvFattureKeap);

            if (csvFattureKeap) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    let fattureKeap = e.target.result;
                    // Modal di attesa
                    MySwal.fire({
                        title: 'Caricamento fatture su Keap...',
                        html: "<p>L'operazione può richiedere alcuni secondi per ciascuna fattura.</p>",
                        allowOutsideClick: () => false,
                        showCancelButton: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            MySwal.showLoading();
                        },
                    });
                    // creazione ordini Keap
                    let res = await axios({
                        method: 'post',
                        url: this.global.apiBaseUrl + '/fatture/csv-keap',
                        data: { file: fattureKeap },
                    });
                    //let res = { data: { fatture: [] } };
                    await new Promise((resolve) => setTimeout(() => resolve(), 1000));
                    MySwal.close();
                    console.log(res);

                    // feedback
                    let fattureCreate = res.data.fatture.filter((f) => !f.err);
                    let fattureFallite = res.data.fatture.filter((f) => !!f.err);
                    MySwal.fire({
                        type: res.code == 200 ? 'success' : !!fattureCreate.length ? 'warning' : 'error',
                        title: 'Risultati creazione ordini Keap',
                        html: `<div style="margin:1rem;border:2px solid #008800;">
                            ${fattureCreate
                                .map(
                                    (f) =>
                                        `${f.res.order.order_title} del ${new Date(f.res.order.order_date).toLocaleDateString()} (contatto Keap ${
                                            f.res.order.contact_id
                                        }) - € ${f.res.payment.payment_amount}`
                                )
                                .join('<br />')}
                        </div>
                        <div style="margin:1rem;border:2px solid #880000;">
                        ${fattureFallite
                            .map((f) => `${f.res['Numero fattura']} del ${new Date(f.res['Data acquisto']).toLocaleDateString()} - ${JSON.stringify(f.err)}`)
                            .join('<br />')}
                        </div>`,
                    });
                };
                reader.readAsText(csvFattureKeap);
            }
        };
        const tastoCSVFattureKeap = (
            <Button id='aggiornaCSVKeap' className='mr-2 btn-grower' variant='secondary' onClick={caricaCSVFattureKeap}>
                <i className='fas fa-sync mr-2'></i>
                Carica fattura/e su Keap da CSV
            </Button>
        );

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            selected: _fattureCandidate, //this.state.fattureCandidate ,
            onSelect: this.gestioneSelezione,
            onSelectAll: this.gestioneSelezionaTutte,
            style: { backgroundColor: 'rgba( 100 , 150 , 200 , 0.2 )' },
            headerColumnStyle: { color: 'blue' },
        };

        const stileRiga = (row, rowIndex) => {
            const cig = row.cig;
            let istanze = 0;
            const style = {};

            // Count the number of invoices with the same "cig"
            // as this one
            this.state.fatture.forEach((f) => {
                if (f.cig == cig && f.cig != '' && f.cig != null) istanze++;
            });

            // If this same "cig" is shared between multiple invoices
            // paint the left border blue
            if (istanze > 1) style.borderLeft = '4px solid #435095';

            style.fontSize = '14px';
            style.fontFamily = "'Poppins' , sans-serif";

            // Paint the "blocked" invoices red
            if (row.cig && typeof row.cig === 'string' && row.cig.charAt(0) === '!') style.backgroundColor = 'rgba(200, 50, 50, 0.08)';

            // Paint the transmitted invoices green
            if (row.data_emissione && row.data_emissione > 10) style.backgroundColor = 'rgba( 50 , 200 , 50 , 0.1 )';

            if (row.blocco === 1) style.borderRight = '2px solid firebrick';

            return style;
        };

        return (
            <>
                {modale}

                {/*  <ModaleRiepilogo mostraRiepilogo={ this.state.mostraRiepilogo }/> */}
                <div style={{ padding: '0' }}>
                    <div style={{ height: '1em' }} className='row'>
                        <div className='float-right'>
                            <label>
                                <Toggle defaultChecked={this.state.includiEmesse} onChange={this.toggleEmesse} />
                                <span
                                    style={{
                                        verticalAlign: 'super',
                                        float: 'left',
                                        marginRight: '1em',
                                        fontSize: '14px',
                                        opacity: '0.9',
                                        fontFamily: "'Quicksand' , sans-serif",
                                    }}
                                >
                                    Includi fatture emesse
                                </span>
                            </label>
                            {/* Filtro data */} {/* filter date-filter-input form-control */}
                            <div style={{ padding: '0.5em' }}>
                                <label style={{ fontSize: '14px', opacity: '0.9', fontFamily: "'Quicksand' , sans-serif" }}>Filtra per data acquisto:</label>
                                <label>
                                    <span style={{ marginRight: '0.5em' }}>dal</span>
                                    <input
                                        className='filter date-filter-input form-control'
                                        style={{ width: 'auto', float: 'right' }}
                                        value={this.state.filtroDataInizio}
                                        type='date'
                                        onChange={this.setFiltroDataInizio}
                                    />
                                </label>
                                <label>
                                    <span style={{ marginRight: '0.5em' }}>al</span>
                                    <input
                                        className='filter date-filter-input form-control'
                                        style={{ width: 'auto', float: 'right' }}
                                        value={this.state.filtroDataFine}
                                        type='date'
                                        onChange={this.setFiltroDataFine}
                                    />
                                </label>
                            </div>
                            {tastoAggiornamento}
                        </div>
                    </div>

                    <br />

                    <div className='row'>
                        {tastoInvio}

                        <TastoElimina azione={() => this.eliminazioneFatture} />
                    </div>

                    <br />

                    <div className='row'>
                        {tastoAggiornaKeap}
                        <i className='fas fa-info-circle cTeal iconButton' onClick={mostraInfoSincroKeap}></i>
                    </div>
                    <br />
                    <div className='row'>
                        {tastoCSVFattureKeap}
                        <i className='fas fa-info-circle cTeal iconButton' onClick={mostraInfoCSVFattureKeap}></i>
                    </div>

                    <br />

                    <ToolkitProvider
                        keyField='id'
                        data={this.state.fatture}
                        columns={colonne}
                        exportCSV={{ exportAll: !this.state.esportaSoloRisultatiFiltrati, onlyExportFiltered: this.state.esportaSoloRisultatiFiltrati }}
                    >
                        {(props) => (
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <ExportCSVButton id='esportaCsv' className='btn-grower' {...props.csvProps}>
                                        <i className='fas fa-table mr-2'></i>
                                        Esporta CSV
                                    </ExportCSVButton>
                                    <div style={{ marginLeft: '3rem' }}>
                                        <span
                                            style={{
                                                verticalAlign: 'super',
                                                float: 'left',
                                                marginRight: '1em',
                                                fontSize: '14px',
                                                opacity: '0.9',
                                                fontFamily: "'Quicksand' , sans-serif",
                                            }}
                                        >
                                            Esporta solo risultati filtrati
                                        </span>
                                        <Toggle defaultChecked={this.state.esportaSoloRisultatiFiltrati} onChange={this.toggleEsportaSoloFiltrati} />
                                    </div>
                                </div>
                                <hr style={{ marginTop: '4rem' }} />
                                <BootstrapTable
                                    classes={this.state.aggiornamentoInCorso ? 'mt-4 sfocato' : 'mt-4'}
                                    striped
                                    filter={filterFactory()}
                                    hover
                                    selectRow={selectRow}
                                    columnToggle
                                    condensed
                                    cellEdit={cellEditFactory({
                                        mode: 'dbclick',
                                    })}
                                    rowStyle={stileRiga}
                                    id='tabellaFatture'
                                    {...props.baseProps}
                                />
                            </div>
                        )}
                    </ToolkitProvider>

                    {this.state.fatture.length === 0 ? (
                        <Jumbotron>
                            <h1 style={{ fontFamily: 'Quicksand , sans-serif' }}>Nessuna fattura</h1>
                            <p style={{ fontFamily: 'Quicksand , sans-serif', opacity: '0.7' }}>
                                Non sono presenti nel database fatture che corrispondano ai criteri di ricerca specificati.
                            </p>
                        </Jumbotron>
                    ) : (
                        <>
                            <div id='numeroFatture'>Fatture mostrate: {this.state.fatture.length}.</div>
                            <div id='ultimoAggiornamento'>Ultimo aggiornamento: {this.state.ultimoAggiornamento}</div>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default InvoicesTable;

import React from 'reactn';

import { Link } from 'react-router-dom';

class SideBar extends React.Component {
    render() {
        return (
            <div
                className='barraLaterale'
                style={{ minHeight: '100vh', marginLeft: 0, boxSizing: 'border-box', display: 'block', width: '200px', minWidth: '200px' }}
            >
                <div className='bg-dark text-light' style={{ height: '54px', padding: '0.875rem 1.25rem', fontSize: '1.2rem' }}>
                    <h5 id='appHeader'>
                        <i id='appIcon' className='fas fa-piggy-bank' style={{ position: 'relative', top: '-4px', marginRight: '10px' }}></i>
                        Plutone
                    </h5>
                </div>

                <div style={{ minHeight: '100vh' }} className='list-group list-group-flush bg-dark'>
                    <Link to='/' className='sidebarLink list-group-item list-group-item-action bg-dark'>
                        <i className='fas fa-tachometer-alt mr-2'></i>
                        Dashboard
                    </Link>
                    <Link to='/docendo' className='sidebarLink list-group-item list-group-item-action bg-dark'>
                        <i className='fas fa-graduation-cap mr-2'></i>
                        Docendo
                    </Link>
                    <Link to='/semplificaci' className='sidebarLink list-group-item list-group-item-action bg-dark'>
                        <i className='fas fa-calculator mr-2'></i>
                        SemplificaCI
                    </Link>

                    <hr />

                    <Link to='/rubrica' className='sidebarLink list-group-item list-group-item-action bg-dark'>
                        <i className='far fa-address-book mr-2'></i>
                        Rubrica utenti
                    </Link>
                    <Link to='/backlog' className='sidebarLink list-group-item list-group-item-action bg-dark'>
                        <i className='fas fa-clipboard-list mr-2'></i>
                        Log eventi
                    </Link>

                    <hr />

                    <Link to='/manuale' className='sidebarLink list-group-item list-group-item-action bg-dark'>
                        <i className='fas fa-question-circle mr-2'></i>
                        Manuale
                    </Link>
                </div>
            </div>
        );
    }
}

export default SideBar;

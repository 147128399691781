import React from 'reactn';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

class Impostazioni extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalloAggiornamentoDashboard: 4,
            intervalloAggiornamentoUtenze: 4,
            intervalloAggiornamentoFatture: 4
        };
    }

    componentDidMount() {
        console.debug('sezione in costruzione....');
    }

    aggiornaImpostazione = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        return (
            <Row>
                <Col className='p-4'>
                    <h2 className='titoloPagina'>Impostazioni</h2>

                    <p>
                        <br />
                    </p>

                    <div>
                        <InputGroup className='mb-4' style={{ width: '100%' }}>
                            <div className='form-group' style={{ width: '100%' }}>
                                <label>Intervallo aggiornamento contatori ( secondi )</label>
                                <input
                                    name='intervalloAggiornamentoDashboard'
                                    type='number'
                                    onChange={this.aggiornaImpostazione}
                                    value={this.state.intervalloAggiornamentoDashboard}
                                    className='inputImpostazioni float-right col-2'
                                />
                            </div>
                        </InputGroup>

                        <hr />

                        <InputGroup className='mb-4' style={{ width: '100%' }}>
                            <div className='form-group' style={{ width: '100%' }}>
                                <label>Intervallo aggiornamento fatture ( ore )</label>
                                <input
                                    name='intervalloAggiornamentoFatture'
                                    type='number'
                                    onChange={this.aggiornaImpostazione}
                                    value={this.state.intervalloAggiornamentoFatture}
                                    className='inputImpostazioni float-right col-2'
                                />
                            </div>
                        </InputGroup>

                        <hr />

                        <InputGroup className='mb-4' style={{ width: '100%' }}>
                            <div className='form-group' style={{ width: '100%' }}>
                                <label>Intervallo aggiornamento utenze ( ore )</label>
                                <input
                                    name='intervalloAggiornamentoUtenze'
                                    type='number'
                                    onChange={this.aggiornaImpostazione}
                                    value={this.state.intervalloAggiornamentoUtenze}
                                    className='inputImpostazioni float-right col-2'
                                />
                            </div>
                        </InputGroup>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default Impostazioni;
